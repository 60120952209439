import React from "react";
import "./Footer.css";
import logo from "../../assets/mP-logo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer" >
      <div className="copyright__footer"> 
        {(window.location.pathname === "/")
          ? <a onClick={() => navigate("/")} rel="noreferrer">
            <img className="footer__logo" src={logo} alt="IMG" />
          </a>
          : null
        }
        <div>© 2022 M&P. All Rights Reserved
          <div>Powered by <a className="link" href="https://quantumparadigm.io/" target="__blank">Quantum Paradigm (PVT) LTD.</a></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
