import React from 'react'
import './AboutUs.css'
import StatsBanner from '../../components/Home/StatsBanner/StatsBanner'
// import background from '../../assets/waveorange4.png'
import image from '../../assets/highLightImage.jpg'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

const AboutUs = () => {
  return (
    <>
      <Header />

      <div className='AboutUs'>
        <div
          className='AboutUs__banner__sec'
        >
          <div className='AboutUs__banner__content'>
            <div className='AboutUs__banner__content__heading'>
              Improving Lives
            </div>
            <div className='AboutUs__banner__content__text'>
              Through Learning
            </div>
          </div>
        </div>
        <div className='About__learning__container'>
          <div className='About_Us_parent'>
            <div className='About_Us_one'>
              <div
                className='About__learning'
                style={
                  window.innerWidth > 950
                    ? {
                        marginLeft: '5%',
                        paddingTop: '10px',
                        paddingBottom: '10px'
                      }
                    : {
                        marginLeft: '0',
                        paddingTop: '10px',
                        paddingBottom: '10px'
                      }
                }
              >
                <div className='About__learning__heading'>
                  About M&P Learning <br /> Circles
                </div>
                <div className='About__learning__text'>
                  M&P is not just a logistics company. It is a family of 3800+
                  professionals that take pride in their work. We believe that
                  dreams deserve to be accomplished. We have crafted a secure,
                  agile, and customizable logistics network to help you achieve
                  them.
                </div>
              </div>
            </div>
            <div
              className='About_Us_two'
              style={
                window.innerWidth > 950
                  ? {
                      minHeight: '400px',
                      backgroundImage: `url(${image})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }
                  : {
                      minHeight: '300px',
                      backgroundImage: `url(${image})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }
              }
            ></div>
          </div>
          <div className='About_Us_parent'>
            <div
              className='About_Us_one'
              style={
                window.innerWidth > 950
                  ? {
                      maxWidth: '60%',
                      minWidth: '60%',
                      minHeight: '400px',
                      backgroundImage: `url(${image})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }
                  : {
                      minHeight: '300px',
                      backgroundImage: `url(${image})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }
              }
            ></div>
            <div
              className='About_Us_two'
              style={
                window.innerWidth > 950
                  ? {
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center'
                    }
                  : {
                      marginTop: '50px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }
              }
            >
              <div
                className='About__learning'
                style={{
                  overflow: 'visible',
                  marginRight: '0%',
                  paddingTop: '10px',
                  paddingBottom: '10px'
                }}
              >
                <div className='About__learning__heading'>
                  About M&P Learning Circles Academy
                </div>
                <div className='About__learning__text'>
                  M&P Learning Academy is a drive to make learning available and
                  reachable to all M&P representatives across Pakistan through
                  digital learning framework (LMS). The crisp, modernized and
                  easy to grasp programs will help employees in improving lives
                  and livelihood.
                </div>
              </div>
            </div>
          </div>
        </div>
        <StatsBanner />
        <div style={{ minHeight: '40px' }}></div>
      </div>
      <Footer />
    </>
  )
}

export default AboutUs
