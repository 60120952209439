import React from 'react'
import "./Testimonials.css"
import TestimonialsList from '../../../components/Admin/Testimonials/TestimonialsList/TestimonialsList'
const Testimonials = () => {
  return (
    <div>
      <TestimonialsList/>
    </div>
  )
}

export default Testimonials