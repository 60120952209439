import React, { useState, useEffect } from 'react'
import './LearningPartners.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import hina from "../../../assets/hina.jpeg"
import rohan from "../../../assets/rohan.jpeg"

const LearningPartners = () => {
  const slideLeft = () => {
    var slider = document.getElementById('learningPartnerSlider')
    windowDimensions.width >= 750
      ? (slider.scrollLeft = slider.scrollLeft - 730)
      : (slider.scrollLeft = slider.scrollLeft - 100)
    //console.log(slider);
  }
  const slideRight = () => {
    var slider = document.getElementById('learningPartnerSlider')
    windowDimensions.width >= 750
      ? (slider.scrollLeft = slider.scrollLeft + 730)
      : (slider.scrollLeft = slider.scrollLeft + 100)
  }
  const hasWindow = typeof window !== 'undefined'
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  function getWindowDimensions () {
    const width = hasWindow ? window.innerWidth : null
    return {
      width
    }
  }

  React.useEffect(() => {
    if (hasWindow) {
      function handleResize () {
        setWindowDimensions(getWindowDimensions())
      }
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [hasWindow])

  // const trainingPartners = [
  //   {
  //     url: hina,
  //     name: 'Ms Hina Ghufran',
  //     designation: 'National Manager CS and L&D',
  //     details:
  //       'Ms Hina Ghufran: is associated with M&P Express Logistics since the acquisition of OCS by M&P earlier in May 2015. She was appointed as National Manager Customer Services to augment the Customer Experience and introduce Call Center platform for M&P Express Logistics. She possesses strong Training and Facilitation Skills and is also leading the Learning & Development function pan Pakistan. She also holds a certified Facilitator certification from DHL Bonn, Germany.'
  //   },
  //   {
  //     url: rohan,
  //     name: 'Rohan Mubashir',
  //     designation: 'Former Deputy CEO ZONG ',
  //     details:
  //       'Rohan Mubashir: Over 6 years’ experience in the L&D and Sales Industry including Business Development, Client Management, People handling and Talent Upskilling. Believing in culture of diversity and inclusion, He enjoys connecting, sharing and engaging with people from diversified backgrounds and varying skills and is proud to support people and personal development and in turn, drive Business Growth.'
  //   }
  //   // {
  //   //   url: "https://lca.mulphico.pk/wp-content/uploads/2021/06/Mian-Atif-Iqbal2-Subject-Matter-Experts-150x150.png",
  //   //   name: "Mian Atif Iqbal",
  //   //   designation: "HR Relationship Manager",
  //   //   details:
  //   //     "Atif is a committed resource having over 22 years of diversified & multicultural experience in the field of HRM. He has also worked with Unilever Pakistan Limited and Dalda Foods Pvt. Ltd as HR Manager.",
  //   // },{
  //   //   url: "https://lca.mulphico.pk/wp-content/uploads/2021/06/Tayyab-Iqbal2-Subject-Matter-Experts-150x150.png",
  //   //   name: "Tayyab Iqbal",
  //   //   designation: "Manager QMS & PI",
  //   //   details:
  //   //     "Tayyab has been associated with M&P since 2015. He is a Certified ISO professional. He has over 14 years of experience. Before joining M&P, Tayyab has worked with Dadex, PICTL, TCS and Agility in different Quality Management and Assurance roles.",
  //   // },
  //   // {
  //   //   url: "https://lca.mulphico.pk/wp-content/uploads/2022/05/Imran-Mazhar-150x150.jpg",
  //   //   name: "IMRAN mazhar",
  //   //   designation: "T&D Manager",
  //   //   details:
  //   //     "Having comprehensive experience of over 15years Imran understands the needs of the organizations considering the aspects of recruitment organizational development and training He started off his professional journey from Mobilink where he served for 10 years",
  //   // },
  //   // {
  //   //   url: "https://lca.mulphico.pk/wp-content/uploads/2022/05/Jahangir-150x150.jpg",
  //   //   name: "Jahangir Sachwani",
  //   //   designation: "External Trainer",
  //   //   details:
  //   //     "Jahangir has been related to the profession of Training and development since 1998 and has conducted many programs and seminars for varied audiences. Currently, he is a certified Microsoft Power BI Trainer and vivacious professional.",
  //   // },
  //   // {
  //   //   url: "https://lca.mulphico.pk/wp-content/uploads/2022/05/Maalik-Khaskhely-150x150.jpg",
  //   //   name: "Maalik Khaskhely",
  //   //   designation: "External Trainer",
  //   //   details:
  //   //     "Maalik Khaskhely has trained thousands towards a better and a sustainable future. Being the executive director for SoL (School of Leadership) he has led several projects like Active Citizens (British Council), Sehatmand Pakistan (UNICEF) etc.",
  //   // },
  //   // {
  //   //   url: "https://lca.mulphico.pk/wp-content/uploads/2022/05/farhan-150x150.jpg",
  //   //   name: "Farhan Ali Jamali",
  //   //   designation: "Trainer and Facilitator",
  //   //   details:
  //   //     "As a Certified Success Coach (Possibilities), Trainer and Facilitator, Farhan Ali Jamali is a decade-strong Learning & OD professional. He is driven by a mission in life that aims to support individuals, organizations, and communities to achieve the results they seek.",
  //   // },
  //   // {
  //   //   url: "https://lca.mulphico.pk/wp-content/uploads/2022/05/tabish-150x150.png",
  //   //   name: "Tabish Hasan Khan",
  //   //   designation: "HRBP",
  //   //   details:
  //   //     `Tabish Hassan Khan is working with us as HRBP for the Telecom division. He has comprehensive experience of over 8 years with proven record of success. He has successfully completed a training certificate called "Train the Emerging Trainer" program and is now a Certified Trainer.`,
  //   // },
  //   // {
  //   //   url: "https://lca.mulphico.pk/wp-content/uploads/2022/05/owais-600x600.jpg",
  //   //   name: "Muhammad Owais ",
  //   //   designation: "Sr. HR Generalist",
  //   //   details:
  //   //     "Muhammad Owais is currently designated as Sr. HR Generalist in Human Resources Division and has been associated with M&P since 2017. He has good command over HR Operations along with Management Reports and Compensation Tools.",
  //   // },
  //   // {
  //   //   url: "https://lca.mulphico.pk/wp-content/uploads/2022/05/Ayaz-1-150x150.png",
  //   //   name: "Ayaz Sario",
  //   //   designation: "External Trainer",
  //   //   details:
  //   //     "An experienced professional with over a decade experience in teaching ESL Classes, IELTS and other Advanced Business Communication courses to diverse participants from academia and corporate backgrounds. He has worked with multiple renowned local and international institutes pertaining to teaching English as Secondary Language.",
  //   // },
  //   // {
  //   //   url: "https://lca.mulphico.pk/wp-content/uploads/2022/05/fff-150x150.jpg",
  //   //   name: "Syed Faisal Raza",
  //   //   designation: "Business Unit Head",
  //   //   details:
  //   //     "Syed Faisal Raza is working for us as a Business Unit Head in our Telecom Division. He has been associated with M&P for over 4 years. His passion for training and development of employees has been instrumental in passing his knowledge onto others. ",
  //   // },
  // ]

  const [trainingPartners, settrainingPartners] = useState();
  
  let skeletons = [1, 2, 3];

  const getActiveTrainingPartners = () => {
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
        "/api/LearningPartners/getActiveTestimonials"
      )
      .then((res) => {
        console.log(res);
        settrainingPartners(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getActiveTrainingPartners();
  }, []);

  return (
    <div className='learning__partner'>
      <div className='learning__partner__heading'>Our Learning Partners</div>
      {trainingPartners ? (
        <>
          <div className='learning__partner__card__btn__box'>
            {windowDimensions.width >= 750 ? (
              <div className='learning__partner__btn' onClick={slideLeft}>
                <ArrowBackIosNewIcon sx={{ color: 'white' }} />
              </div>
            ) : null}
            <div
              id='learningPartnerSlider'
              className='learning__partner__cards__container'
            >
              {trainingPartners.map((cur, ind) => (
                <div className='learning__partner___card' key={ind}>
                  <div className='card__img'>
                    <img className='card__img' src={cur.authorImage} />
                  </div>
                  <div className='card__name'>{cur.authorName}</div>
                  <div className='card__designation'>{cur.authorDesignation}</div>
                  <div className='card__details'>{cur.testimonial}</div>
                </div>
              ))}
            </div>
            {windowDimensions.width >= 750 ? (
              <div className='learning__partner__btn' onClick={slideRight}>
                <ArrowForwardIosIcon sx={{ color: 'white' }} />
              </div>
            ) : null}
          </div>

          <div className='learning__partner__btn__box'>
            {windowDimensions.width <= 750 ? (
              <div className='learning__partner__btn' onClick={slideLeft}>
                {<ArrowBackIosNewIcon sx={{ color: 'white' }} />}
              </div>
            ) : null}
            {windowDimensions.width <= 750 ? (
              <div className='learning__partner__btn' onClick={slideRight}>
                <ArrowForwardIosIcon sx={{ color: 'white' }} />
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <div className="skeleton__container">
          {skeletons.map((cur, ind) => {
            return (
              <div key={ind} className="skeletons">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="200px"
                  width="90%"
                  style={{ margin: "10px auto" }}
                />

                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={60}
                  height={60}
                  style={{ margin: "10px auto" }}
                />
                <Skeleton
                  animation="wave"
                  height={20}
                  width="30%"
                  style={{ margin: "auto" }}

                />
                <Skeleton
                  animation="wave"
                  height={10}
                  width="50%"
                  style={{ margin: "auto" }}

                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  )
}

export default LearningPartners
