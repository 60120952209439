import React from 'react'
import "./Banner.css"
import UpdateBannerForm from '../../../components/Admin/Banner/UpdateBannerForm/UpdateBannerForm'

const Banner = () => {
  return (
    <div>
    <UpdateBannerForm/>
    </div>
  )
}

export default Banner