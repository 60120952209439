import React from 'react';
import "./PDFToPrint.css"
// import signImage from "../../assets/lca-logo.png"
// import e_certificate from "../../assets/certificate/e_certificate.png"
// import for_completing from "../../assets/certificate/for_completing.jpeg"
// import proudly_awarded_to from "../../assets/certificate/proudly_awarded_to.jpeg"

export const PDFToPrint = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} className="PDFToPrint" id="PDFBack">
            <style type="text/css" media="print">{"\
               @page {\ size: landscape;\ }\
            "}</style>
            <div className="PDFToPrint__container">
                <div className="PDFToPrint__text">
                    <div className="PDFToPrint__text__1">
                        <span className="PDFToPrint__text__1__grey">This is to certify that</span>
                        <span className="PDFToPrint__text__1__orange">{" " + props.name + " "}</span>
                        <span className="PDFToPrint__text__1__grey">has successfully completed the training course on</span>
                    </div>
                    <div className="PDFToPrint__text__2">
                        <q>{props.course}</q>
                    </div>
                    <div className="PDFToPrint__text__3">
                        {(props.date) ? ("on " + new Date(props.date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })) : "Missing Date"}
                    </div>
                    {/* <img src={e_certificate} className="PDFToPrint__left__head" /> */}
                    {/* <div className="PDFToPrint__left__head__text__mid">
                        <div className="PDFToPrint__left__head__text__mid__space"></div>
                        <div className="PDFToPrint__left__head__text__mid__text">of appreciation</div>
                        <div className="PDFToPrint__left__head__text__mid__space"></div>
                    </div> */}
                    {/* <img src={proudly_awarded_to} className="PDFToPrint__left__head__text__small" />
                    <div className="PDFToPrint__left__name">{props.name}</div>
                    <img src={for_completing} className="PDFToPrint__left__pictext" />
                    <div className="PDFToPrint__left__training">{props.course}</div>
                    <div className="PDFToPrint__left__datetext">{(props.date) ? ("on " + new Date(props.date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })) : ""}</div>
                    <div className="PDFToPrint__left__sign">
                        <div className="PDFToPrint__left__sign__one">
                            <img className="PDFToPrint__left__sign__one__picture" src={signImage} />
                            <div className="PDFToPrint__left__sign__one__name">Imran Mazhar</div>
                            <div className="PDFToPrint__left__sign__one__designation">T&D Manager</div>
                        </div>
                        <div className="PDFToPrint__left__sign__one">
                            <img className="PDFToPrint__left__sign__one__picture" src={signImage} />
                            <div className="PDFToPrint__left__sign__one__name">Ali Raza</div>
                            <div className="PDFToPrint__left__sign__one__designation">Acting Head of HR</div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
});