import React from 'react'
import "./Gallery.css"
import ImagesList from '../../../components/Admin/Gallery/ImagesList/ImagesList'
const Gallery = () => {
  return (
    <div>
      <ImagesList/>
    </div>
  )
}

export default Gallery