import React from 'react'
import "./LearningPartners.css"
import LearningPartnersList from '../../../components/Admin/LearningPartners/LearningPartnersList/LearningPartnersList'
const LearningPartners = () => {
  return (
    <div>
      <LearningPartnersList/>
    </div>
  )
}

export default LearningPartners